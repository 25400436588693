<template>
	<div class="flexCloumn">

		<el-table v-tableFit :data="tableData" height="100%" size="small" stripe border
			style="width:fit-content;width:100%;" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55" align="center">
			</el-table-column>
			<el-table-column type="index" label="序号" width="55" align="center">
				<template slot-scope="scope">
					<span>{{ (Page.current_page - 1) * Page.per_page + scope.$index + 1 }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="stu_code" label="学号" width="135" align="center"></el-table-column>
			<el-table-column v-if="$store.state.pmid == 214" prop="check_status" label="抽查状态" width="100"
				align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.check_status == 1">已抽查</span>
				</template>
			</el-table-column>

			<el-table-column prop="stu_name" label="学生姓名" width="100" align="center">
				<template slot-scope="scope">
					<span style="color: #007AFF; cursor: pointer;" v-if="scope.row.openid"
						@click="showStuBind(scope.row)">{{ scope.row.stu_name }}</span>
					<span v-else>{{ scope.row.stu_name }}</span>
					<i class="el-icon-link" v-if="scope.row.openid"></i>
				</template>
			</el-table-column>
			<el-table-column prop="phone" label="性别" width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.gender == 1">男</span>
					<span v-if="scope.row.gender == 2">女</span>
					<span v-if="!scope.row.gender">-</span>
				</template>
			</el-table-column>
			<el-table-column v-if="sch_type == 'xx'" prop="exam_number" label="考试号" min-width="120" align="center">
			</el-table-column>
			<el-table-column prop="phone" label="手机号" width="135" align="center"></el-table-column>
			<el-table-column prop="class_name" label="班级" width="130" align="center"></el-table-column>
			<el-table-column prop="class_name" label="小组" align="center">
				<template slot-scope="scope">
					<div style="cursor: pointer;" v-for="(cls, idx) in scope.row.class_arr" :key="idx">
						{{ cls.class_name }}<i class="el-icon-remove" @click="removeGroup(cls)"></i></div>

				</template>

			</el-table-column>
			<el-table-column prop="sxqx" label="实习去向" width="100" align="center">
				<template slot-scope="scope">
					<span>{{ scope.row.sxqxLabel || '-' }}</span>
				</template>
			</el-table-column>

			<el-table-column prop="firm_name" label="实习企业" show-overflow-tooltip min-width="200"
				align="center"></el-table-column>
			<el-table-column prop="class_name" label="实习周期" min-width="240" align="center">
				<template slot-scope="scope">
					<span>{{ scope.row.start_time }}</span>~<span>{{ scope.row.end_time }}</span>

				</template>

			</el-table-column>



			<el-table-column fixed="right" label="操作" width="260" align="center">
				<template slot-scope="scope">
					<div v-if="!isGradeLeader(scope.row.class_id)">
						<div v-if="!scope.row.locked && page_from!='bzr'">
							<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
								iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
								<el-tag class="pointer" slot="reference" type="danger" size="mini"
									style="margin-right: 10px;">删除</el-tag>

							</el-popconfirm>
						</div>
						<el-tag v-if="!scope.row.locked" class="pointer" @click="handleModify(scope.row)" type="primary"
							size="mini">修改</el-tag>

						<el-tag v-if="scope.row.sp_status == 1 && needsp" class="pointer"
							@click="handleModify(scope.row)" type="success" size="mini">审批</el-tag>

						<!-- <el-popconfirm v-if="!scope.row.locked" width="250" confirmButtonText='确定' cancelButtonText='取消'
							icon="el-icon-info" iconColor="red" @confirm="resetPwd(scope.row)" placement="left"
							title="是否确定重置？">
							<el-tag v-if="!scope.row.locked" class="pointer" slot="reference" type="warning" size="mini"
								style="margin-left: 10px">重置密码</el-tag>
						</el-popconfirm> -->

						<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
							iconColor="red" @confirm="resetPwd(scope.row)" placement="left"
							:title="default_pwd ? ('是否确定重置？默认密码为:' + default_pwd) : '是否确定重置？默认密码请在配置中查看!'">
							<el-tag class="pointer" slot="reference" type="warning" size="mini"
								:title="default_pwd ? ('默认密码:' + default_pwd) : '默认密码请在配置中查看'"
								style="margin-left: 10px">重置密码</el-tag>
						</el-popconfirm>


					</div>
					<div>
						<el-tag v-if="!isMedicalSchool" class="pointer" @click="downHankbook(scope.row)" type="success"
							size="mini" style="margin-left: 10px">实习手册</el-tag>
						<el-tag v-if="isMedicalSchool" class="pointer" @click="downHankbook_week_report(scope.row)"
							type="success" size="mini" style="margin-left: 10px">周记本</el-tag>
						<el-tag v-if="isMedicalSchool" class="pointer" @click="downHankbook_keshi(scope.row)"
							type="success" size="mini" style="margin-left: 10px">科室实习手册</el-tag>

						<el-tag class="pointer" @click="openXxcj(scope.row)" type="primary" size="mini"
							style="margin-left: 10px">信息采集</el-tag>

					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
			:current-page="Page.current_page" :page-size="Page.per_page" :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
			layout="total, prev, pager, next,sizes, jumper" :total="Page.count">
		</el-pagination>
		<bindstu v-if="showBind" :stuid="stuid"></bindstu>
		<xxcj v-if="showXxcj" :stuid="stuid"></xxcj>
	</div>
</template>
<script>
import bindstu from "./bindstu.vue"
import xxcj from "./xxcj.vue";
import { mapGetters } from "vuex";

export default {
	name: 'Table',
	components: {
		bindstu, xxcj
	},
	data() {
		return {
			showBind: false,
			showXxcj: false,
			stuid: 0,

			sch_type: "",
			Page: {},
			default_pwd: '',

		}
	},
	props: {
		page: {
			type: Object,
		},
		tableData: {
			type: Array,
			default: []
		},
		from: {
			type: String,
			default: ''
		},
		multSelect: {
			type: Boolean,
			default: true
		},
		schType: {
			type: String,
			default: ''
		},
		needsp: {
			type: Boolean,
			default: false
		},
		isMedicalSchool: {
			type: Boolean,
			default: false
		},
		page_from: {
			type: String,
			default: ''
		}
	},
	watch: {
		schType: function (n, o) {
			this.sch_type = n
		},
		page: function (n, o) {
			this.Page = n
		}
	},
	mounted() {
		this.Page = this.page
		this.getConfig()

	},
	computed: {
		...mapGetters(['isGradeLeader']),
	},
	methods: {
			getConfig() {
			this.$http.post("/api/sys_sch_config_list", {
				keyword: "default_pwd",
				ktype: "ktype"
			}).then(res => {
				for (let item of res.data.data) {
					this.default_pwd = item.kvalue
				}
			})
		},
		handleModify(row) {
			this.$$parent(this, "student_id", row.id)
			this.$$parent(this, "showTeacherInfo", true)
		},
		handleDel(row) {
			this.$emit('handleDel', row)
		},
		handleSelectionChange(val) {
			this.$emit('handleSelectionChange', val)
		},
		handlePageChange(e) {
			this.$$parent(this, "page").then(page => {
				page.current_page = e
				this.$$parent(this, "page", page)
				this.$$parent(this, "getStudents")

			})

		},
		handleSizeChange(e) {

			this.$$parent(this, "page").then(page => {
				page.per_page = e
				this.$$parent(this, "page", page)
				this.$$parent(this, "getStudents")

			})

		},
		showOneDept(e) {
			this.$emit('showOneDept', e)
		},
		showOneTeacher(e) {
			this.$emit('showOneTeacher', e)
		},
		resetPwd(row) {
			// this.$confirm(`确认将学生密码重置为${window.pmid == 214 ? '123456' : '手机号后六位'}?`, '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	type: 'warning'
			// }).then(() => {
				this.$http.post("/api/reset_student_pwd", { id: row.id }).then(res => {
					this.$message({
						type: 'success',
						message: '重置成功!'
					});


				})

			// })

		},
		showStuBind(row) {
			this.stuid = row.id
			this.showBind = true

		},
		removeGroup(row) {

			this.$confirm('确认将学生移除【' + row.class_name + '】?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/sys_stu_class_related_delete", { id: row.id }).then(res => {
					this.$message({
						type: 'success',
						message: '移除成功!'
					});
					this.$$parent(this, "getStudents");

				})

			})
		},
		openXxcj(row) {
			this.stuid = row.id
			this.showXxcj = true
		},
		downHankbook(row) {
			/** 导出学生实习手册
			 * @param {*} opt.ids 学生ids (逗号隔开) (必传)
			 * @param {*} opt.latest 是否是最新(0: 否 | 1: 是)
			 * @returns
			 */
			let params = {
				"stuArr": [{
					"id": row.id,
					"name": row.stu_name,
					"class_name": row.class_name
				}],
				"pmid": window.pmid,
				"latest": 1
			}

			this.$http.post(this.$pdfUrl + "/exportStuPdf", params).then(res => {
				this.$message({
					type: 'success',
					message: '生成成功!'
				});
				window.open(res.data.url, "_blank")
			})


		},
		downHankbook_week_report(row) {
			/** 导出学生周记本
			 * @param {*} opt.ids 学生ids (逗号隔开) (必传)
			 * @param {*} opt.latest 是否是最新(0: 否 | 1: 是)
			 * @returns
			 */
			let params = {
				"stuArr": [{
					"id": row.id,
					"name": row.stu_name,
					"class_name": row.class_name
				}],
				"pmid": window.pmid,
				"latest": 1
			}
			this.$http.post(this.$pdfUrl + "/export_medical_report_a", params).then(res => {
				this.$message({
					type: 'success',
					message: '生成成功!'
				});
				window.open(res.data.url, "_blank")
			})
		},
		downHankbook_keshi(row) {
			/** 导出学生周记本
			 * @param {*} opt.ids 学生ids (逗号隔开) (必传)
			 * @param {*} opt.latest 是否是最新(0: 否 | 1: 是)
			 * @returns
			 */
			let params = {
				"stuArr": [{
					"id": row.id,
					"name": row.stu_name,
					"class_name": row.class_name
				}],
				"pmid": window.pmid,
				"latest": 1
			}
			this.$http.post(this.$pdfUrl + "/export_medical_keshi_a", params).then(res => {
				this.$message({
					type: 'success',
					message: '生成成功!'
				});
				window.open(res.data.url, "_blank")
			})
		}

	}
}
</script>
